import { useMediaQuery } from "@mantine/hooks";
import { CheckIcon, ChevronsUpDownIcon } from "lucide-react";
import { useMemo, useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/components/ui/command";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { ScrollArea } from "@/components/ui/scroll-area";
import { cn } from "@/utils";

import { useCompanies } from "../pages/deliveries/hooks/use-companies";

export type CompaniesComboboxProps = {
  value: string;
  onChange: (value: string) => void;
  disabled?: boolean;
};

export function CompaniesCombobox({
  value,
  onChange,
  disabled = false,
}: CompaniesComboboxProps) {
  const [open, setOpen] = useState(false);
  const companies = useCompanies();
  const isDesktop = useMediaQuery("(min-width: 768px)");

  const companyName = useMemo(() => {
    if (!companies.isFetched || !companies.data) {
      return "Selecione uma empresa";
    }

    const company = companies.data.data.find((company) => company.id === value);

    return company?.companyName || "Selecione uma empresa";
  }, [companies.data, value]);

  if (isDesktop) {
    return (
      <Popover modal open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            disabled={disabled}
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            {companyName}

            <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>

        <PopoverContent className="popover-content-width-same-as-its-trigger p-0">
          <CompaniesList
            selectedCompanyId={value}
            setSelectedCompany={(value) => {
              onChange(value);
              setOpen(false);
            }}
          />
        </PopoverContent>
      </Popover>
    );
  }

  return (
    <Drawer open={open} onOpenChange={setOpen}>
      <DrawerTrigger asChild>
        <Button
          disabled={disabled}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-full justify-between"
        >
          {companyName}

          <ChevronsUpDownIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </DrawerTrigger>

      <DrawerContent>
        <div className="mt-4 border-t">
          <CompaniesList
            selectedCompanyId={value}
            setSelectedCompany={(value) => {
              onChange(value);
              setOpen(false);
            }}
          />
        </div>
      </DrawerContent>
    </Drawer>
  );
}

type CompaniesListProps = {
  selectedCompanyId: string;
  setSelectedCompany: (value: string) => void;
};

function CompaniesList({
  selectedCompanyId,
  setSelectedCompany,
}: CompaniesListProps) {
  const companies = useCompanies();

  return (
    <Command
      filter={(value, search) => {
        const searchLower = search
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        const valueLower = value
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");

        if (valueLower.includes(searchLower)) return 1;
        return 0;
      }}
    >
      <CommandInput placeholder="Buscar por empresa..." />

      <CommandEmpty className="h-fit py-2 text-center text-xs">
        Nenhuma empresa encontrada
      </CommandEmpty>

      <CommandGroup>
        {companies.isLoading && (
          <CommandItem disabled>Carregando...</CommandItem>
        )}

        <ScrollArea className="w-full [&>[data-radix-scroll-area-viewport]]:max-h-[300px]">
          {companies.isFetched &&
            companies.data?.data.map((company) => {
              const isSelected = selectedCompanyId === company.id;

              return (
                <CommandItem
                  key={company.companyName}
                  value={company.companyName}
                  onSelect={() => setSelectedCompany(company.id)}
                >
                  {isSelected && <CheckIcon className={cn("mr-2 h-4 w-4")} />}

                  {company.companyName}
                </CommandItem>
              );
            })}
        </ScrollArea>
      </CommandGroup>
    </Command>
  );
}
