import { zodResolver } from "@hookform/resolvers/zod";
import { XIcon } from "lucide-react";
import { ChangeEvent, useRef } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import { queryClient } from "@/app";
import { CompaniesCombobox } from "@/components/companies-combobox";
import {
  PrettyInput,
  PrettyInputControl,
  PrettyInputFooter,
  PrettyInputFormMessage,
  PrettyInputItem,
  PrettyInputLabel,
} from "@/components/pretty-input";
import {
  SwitchBox,
  SwitchBoxDescription,
  SwitchBoxTitle,
} from "@/components/switch-box";
import { Button } from "@/components/ui/button";
import { Form, FormMessage } from "@/components/ui/form";
import { Input, PasswordInput } from "@/components/ui/input";
import { PhoneInput } from "@/components/ui/phone-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Tooltip,
  TooltipProvider,
  TooltipTrigger,
  TooltipContent,
} from "@/components/ui/tooltip";
import { usersApi } from "@/lib/users-api";
import { maskPhone } from "@/utils";

const updateUserFormSchema = z.object({
  id: z.string().uuid("Identificador inválido"),
  name: z.string().min(3, "O nome deve ter no mínimo 3 caracteres"),
  email: z.string().email("Insira um endereço de e-mail válido"),
  password: z.string().min(6, "A senha deve ter no mínimo 6 caracteres"),
  cellphone: z
    .string()
    .min(11, "O número de celular deve ter no mínimo 11 caracteres")
    .optional(),
  telephone: z
    .string()
    .min(10, "O número de telefone deve ter no mínimo 10 caracteres")
    .optional(),
  document: z.string().min(11, "O documento deve ter 11 caracteres"),
  companyId: z.string().uuid("Identificador inválido").nullable(),
  roleId: z.string(),
  isBlocked: z.boolean(),
});

export type UpdateUserProps = {
  userId: string;
};

export function UpdateUser({ userId }: UpdateUserProps) {
  const { data: user } = usersApi.useSuspenseQuery(
    "get",
    "/v1/users/{id}",
    {
      params: {
        path: { id: userId },
      },
    },
    {},
    queryClient,
  );

  return (
    <UpdateUserForm
      id={user.id}
      name={user.name}
      email={user.email}
      document={user.document}
      companyId={user.company?.id || null}
      roleId={user.role[0]?.id.toString() || ""}
      isBlocked={user.isBlocked}
      cellphone={user.cellphone || ""}
      telephone={user.telephone || ""}
    />
  );
}

export type UpdateUserFormProps = {
  id: string;
  name: string;
  email: string;
  document: string;
  companyId: string | null;
  roleId: string | null;
  isBlocked: boolean;
  cellphone: string;
  telephone: string;
};

const translatedKeys = {
  name: "Nome completo",
  email: "Endereço de e-mail",
  document: "Documento",
  password: "Senha",
  companyId: "Empresa",
  roleId: "Cargo",
  isBlocked: "Bloqueado",
};

export function UpdateUserForm({
  id,
  name,
  email,
  document,
  companyId,
  roleId,
  isBlocked,
  cellphone,
}: UpdateUserFormProps) {
  const form = useForm<z.infer<typeof updateUserFormSchema>>({
    resolver: zodResolver(updateUserFormSchema),

    defaultValues: {
      id,
      name,
      email,
      document,
      companyId,
      roleId: roleId || "",
      isBlocked,
      cellphone,
    },
  });

  const { mutate: updateUser } = usersApi.useMutation(
    "patch",
    "/v1/users/{id}",
    {
      onSuccess: (_, req) => {
        queryClient.invalidateQueries({
          queryKey: ["get", "/v1/users/{id}"],
        });

        const updatedFields = Object.keys(req.body).map((key) => {
          if (key in translatedKeys) {
            return translatedKeys[key as keyof typeof translatedKeys];
          }

          return key;
        });

        if (updatedFields.length > 1) {
          toast.success("Sucesso", {
            description: `Os campos ${updatedFields.join(", ")} foram atualizados com sucesso`,
          });
          return;
        }

        if (updatedFields.length === 1) {
          toast.success("Sucesso", {
            description: (
              <div>
                O campo <strong>{updatedFields[0]!.toLowerCase()}</strong> foi
                atualizado com sucesso
              </div>
            ),
          });
          return;
        }

        toast.success("Sucesso", {
          description: "O usuário foi atualizado com sucesso",
        });
      },

      onError: (error) => {
        toast.error("Erro", {
          description: "Ocorreu um erro ao atualizar o usuário",
        });
      },
    },
  );

  return (
    <Form {...form}>
      <PrettyInput
        control={form.control}
        name="name"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>Nome completo</PrettyInputLabel>

            <PrettyInputControl>
              <Input {...field} />
            </PrettyInputControl>

            <FormMessage />

            <PrettyInputFooter>
              <div>O nome completo do usuário</div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: field.value },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />

      <PrettyInput
        control={form.control}
        name="email"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>Endereço de e-mail</PrettyInputLabel>

            <PrettyInputControl>
              <Input type="email" {...field} />
            </PrettyInputControl>

            <PrettyInputFormMessage />

            <PrettyInputFooter>
              <div>
                Endereço de e-mail do usuário. Esse e-mail será o e-mail usado
                para 2FA.
              </div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: field.value },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />

      <PrettyInput
        control={form.control}
        name="cellphone"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>Número de celular</PrettyInputLabel>

            <PrettyInputControl>
              <PhoneInput
                defaultValue={maskPhone(field.value)}
                onInput={(e) => {
                  field.onChange(e.currentTarget.value);
                }}
              />
            </PrettyInputControl>

            <PrettyInputFormMessage />

            <PrettyInputFooter>
              <div>
                O número de celular do usuário. Esse número será usado para
                envio de SMSs e para autenticação de 2 fatores.
              </div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: field.value },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />

      <PrettyInput
        control={form.control}
        name="roleId"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>Cargo</PrettyInputLabel>

            <PrettyInputControl>
              <div className="flex justify-between gap-2">
                <TooltipProvider delayDuration={0}>
                  <Select
                    defaultValue={field.value}
                    onValueChange={field.onChange}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Selecionar cargo" />
                    </SelectTrigger>

                    <SelectContent>
                      <SelectItem value="1">Mestre</SelectItem>
                      <SelectItem value="2">Dono da empresa</SelectItem>
                      <SelectItem value="3">Funcionárioa da empresa</SelectItem>
                      <SelectItem value="4">Entregador</SelectItem>
                    </SelectContent>
                  </Select>
                </TooltipProvider>
              </div>
            </PrettyInputControl>

            <PrettyInputFormMessage />

            <PrettyInputFooter>
              <div>Selecione a empresa a qual o usuário está vinculado.</div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: Number(field.value) },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />

      <PrettyInput
        control={form.control}
        name="companyId"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>Empresa</PrettyInputLabel>

            <PrettyInputControl>
              <div className="flex justify-between gap-2">
                <TooltipProvider delayDuration={0}>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        type="button"
                        variant="outline"
                        onClick={() => field.onChange(null)}
                      >
                        <XIcon className="w-3 h-3" />
                      </Button>
                    </TooltipTrigger>

                    <TooltipContent>Desvincular empresa</TooltipContent>
                  </Tooltip>
                </TooltipProvider>

                <CompaniesCombobox
                  value={field.value || ""}
                  onChange={field.onChange}
                />
              </div>
            </PrettyInputControl>

            <PrettyInputFormMessage />

            <PrettyInputFooter>
              <div>Selecione a empresa a qual o usuário está vinculado.</div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: field.value || undefined },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />

      <PrettyInput
        control={form.control}
        name="document"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>Documento (CPF ou CNPJ)</PrettyInputLabel>

            <PrettyInputControl>
              <DocumentInput
                cpfLabel="CPF"
                cnpjLabel="CNPJ"
                value={field.value}
                onChange={field.onChange}
              />
            </PrettyInputControl>

            <PrettyInputFormMessage />

            <PrettyInputFooter>
              <div>
                Documento do usuário, caso seja pessoa física, insira o CPF,
                caso seja pessoa jurídica, insira o CNPJ.
              </div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: field.value },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />

      <PrettyInput
        control={form.control}
        name="isBlocked"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>
              Bloquear usuário
              <SwitchBox
                checked={field.value}
                onCheckedChange={field.onChange}
                className="mt-2"
              >
                <SwitchBoxTitle>Bloquear usuário</SwitchBoxTitle>

                <SwitchBoxDescription>
                  Clique para bloquear o usuário
                </SwitchBoxDescription>
              </SwitchBox>
            </PrettyInputLabel>

            <PrettyInputFormMessage />

            <PrettyInputFooter>
              <div>
                Ao bloquear o usuário, ele não poderá acessar o sistema.
              </div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: field.value },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />

      <PrettyInput
        control={form.control}
        name="password"
        render={({ field }) => (
          <PrettyInputItem>
            <PrettyInputLabel>Senha</PrettyInputLabel>

            <PrettyInputControl>
              <PasswordInput type="password" {...field} />
            </PrettyInputControl>

            <PrettyInputFormMessage />

            <PrettyInputFooter>
              <div>Escolha uma senha segura, com no mínimo 6 caracteres.</div>

              <Button
                type="button"
                size="sm"
                className="w-fit"
                onClick={async () => {
                  const isValid = await form.trigger(field.name);

                  if (isValid) {
                    updateUser({
                      params: { path: { id } },
                      body: { [field.name]: field.value },
                    });
                  }
                }}
              >
                Salvar
              </Button>
            </PrettyInputFooter>
          </PrettyInputItem>
        )}
      />
    </Form>
  );
}

const CPF_MASK = "___.___.___-__";
const CNPJ_MASK = "__.___.___/____-__";

type DocumentInputProps = {
  value: string;
  onChange: (value: string) => void;
  cpfLabel: string;
} & {
  value: string;
  onChange: (value: string) => void;
  cnpjLabel: string;
};

function DocumentInput({ value, onChange }: DocumentInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const formatCpf = (value: string) => {
    const formattedCpf = value
      .replace(/\D/g, "")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,2})$/, "$1-$2");

    if (formattedCpf.length <= 14) {
      return formattedCpf + CPF_MASK.substring(formattedCpf.length);
    }

    return formattedCpf;
  };

  const formatCnpj = (value: string) => {
    const formattedCnpj = value
      .replace(/\D/g, "")
      .replace(/(\d{2})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d)/, "$1.$2")
      .replace(/(\d{3})(\d{1,4})/, "$1/$2")
      .replace(/(\d{4})(\d{1,2})$/, "$1-$2");

    if (formattedCnpj.length <= 18) {
      return formattedCnpj + CNPJ_MASK.substring(formattedCnpj.length);
    }

    return formattedCnpj;
  };

  const formatDocument = (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    if (numericValue.length > 11) {
      return formatCnpj(value);
    }

    return formatCpf(value);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\D/g, "");

    if (inputValue.length > 14) {
      e.preventDefault();
      return false;
    }

    const formattedValue = formatDocument(inputValue);
    const lastDigitPosition = formattedValue
      .split("")
      .findLastIndex((char, index) => {
        const isNumeric = !isNaN(parseInt(char, 10));

        if (isNumeric) {
          console.log({ char, index });
          return true;
        }

        return false;
      });
    onChange(formattedValue);

    queueMicrotask(() => {
      if (inputRef.current) {
        inputRef.current.setSelectionRange(
          lastDigitPosition + 1,
          lastDigitPosition + 1,
        );
      }
    });
  };

  return (
    <input
      id="document"
      type="text"
      value={formatDocument(value)}
      onChange={handleChange}
      onClick={(e) => {
        e.preventDefault();
        const lastDigitPosition = e.currentTarget.value
          .split("")
          .findLastIndex((char) => !isNaN(parseInt(char, 10)));

        e.currentTarget.setSelectionRange(
          lastDigitPosition + 1,
          lastDigitPosition + 1,
        );
      }}
      ref={inputRef}
      className="flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50"
    />
  );
}
