import { Settings2Icon } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

import { CreatePreferencesDialog } from "../dialogs/preferences";
import { usePreferencesDialog } from "../hooks/dialogs/use-preferences-dialog";

export function PreferencesButton() {
  const preferences = usePreferencesDialog();

  return (
    <>
      <TooltipProvider delayDuration={0}>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button
              variant="outline"
              className="flex gap-2"
              onClick={() => preferences.open()}
            >
              <Settings2Icon className="h-3 w-3" />
            </Button>
          </TooltipTrigger>

          <TooltipContent>Preferências</TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <CreatePreferencesDialog />
    </>
  );
}
