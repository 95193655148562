import { EyeOffIcon } from "lucide-react";
import * as React from "react";

import { cn } from "@/utils";

import { Button } from "./button";

export type InputProps = React.InputHTMLAttributes<HTMLInputElement>;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full rounded-md border border-input bg-background px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);

Input.displayName = "Input";

export const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, ...props }, ref) => {
    const [showPassword, setShowPassword] = React.useState(false);

    return (
      <div className="relative">
        <span className="sr-only">Mostrar senha</span>

        <Input
          {...props}
          type={showPassword ? "text" : "password"}
          ref={ref}
          className={cn("pr-9", className)}
        />

        <Button
          type="button"
          variant="ghost"
          size="icon"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute right-1 top-1 h-7 w-7 rounded-lg"
        >
          {showPassword ? (
            <EyeOffIcon className="w-3 h-3" />
          ) : (
            <EyeOffIcon className="w-3 h-3" />
          )}
        </Button>
      </div>
    );
  },
);

export { Input };
