import {
  BarChart,
  ContactIcon,
  LandPlot,
  LandmarkIcon,
  ListTodoIcon,
  LucideIcon,
  MapPinned,
  PackageIcon,
  UsersIcon,
  PieChart,
} from "lucide-react";

import { HelmetIcon } from "@/components/helmet-icon";

export type Page =
  | {
      icon: LucideIcon | typeof HelmetIcon;
      label: string;
      path: string;
      type: "DEFAULT";
      isMasterOnly: boolean;
      isCompanyMasterOnly?: boolean;
    }
  | {
      icon: LucideIcon | typeof HelmetIcon;
      label: string;
      type: "COLLAPSIBLE";
      items: Page[];
      isMasterOnly: boolean;
      isCompanyMasterOnly?: boolean;
    };

export const PAGES = [
  {
    icon: UsersIcon,
    label: "Usuários",
    path: "/dashboard/users",
    type: "DEFAULT",
    isMasterOnly: true,
  },

  {
    icon: ContactIcon,
    label: "Clientes",
    path: "/dashboard/clients",
    type: "DEFAULT",
    isMasterOnly: true,
  },

  {
    icon: ListTodoIcon,
    label: "Planos",
    path: "/dashboard/plans",
    type: "DEFAULT",
    isMasterOnly: true,
  },

  {
    icon: MapPinned,
    label: "Bairros",
    path: "/dashboard/neighborhoods",
    type: "DEFAULT",
    isMasterOnly: true,
  },

  {
    icon: LandPlot,
    label: "Regiões",
    path: "/dashboard/regions",
    type: "DEFAULT",
    isMasterOnly: true,
  },

  {
    icon: LandmarkIcon,
    label: "Empresa",
    path: "/dashboard/companies",
    type: "DEFAULT",
    isMasterOnly: true,
  },

  {
    icon: HelmetIcon,
    label: "Entregadores",
    path: "/dashboard/deliverymans",
    type: "DEFAULT",
    isMasterOnly: true,
  },

  {
    icon: PackageIcon,
    label: "Entregas",
    path: "/dashboard/deliveries",
    type: "DEFAULT",
    isMasterOnly: false,
  },

  {
    icon: BarChart,
    label: "Relatório",
    path: "/dashboard/reports",
    type: "DEFAULT",
    isMasterOnly: false,
    isCompanyMasterOnly: true,
  },

  {
    icon: PieChart,
    label: "Estatísticas",
    path: "/dashboard/stats",
    type: "DEFAULT",
    isMasterOnly: true,
  },
] satisfies Page[];
