import { MaskitoOptions } from "@maskito/core";
import { useMaskito } from "@maskito/react";

import { Input } from "./input";

const phoneMaskOptions = {
  mask: [
    "(",
    /[1-9]/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d?/,
  ],
} as MaskitoOptions;

type PhoneInputOptions = React.ComponentProps<typeof Input>;

export function PhoneInput(props: PhoneInputOptions) {
  const phoneMask = useMaskito({
    options: phoneMaskOptions,
  });

  return <Input ref={phoneMask} {...props} />;
}
