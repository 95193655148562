import { DotsHorizontalIcon } from "@radix-ui/react-icons";
import { useEffect } from "react";

import { AnimatedLoading } from "@/components/animated-loading";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { usePage } from "@/hooks/use-page";

import { UpdateNeighborhoodDialog } from "../dialogs/update-neighborhood";
import { useDeleteNeighborhoodDialog } from "../hooks/dialogs/use-delete-neighborhood-dialog";
import { useUpdateNeighborhoodDialog } from "../hooks/dialogs/use-update-neighborhood-dialog";
import { useNeighborhoodsQuery } from "../hooks/queries/use-neighborhoods-query";

export function NeighborhoodTable() {
  return (
    <div className="bg-background border rounded-md">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Nome</TableHead>
            <TableHead>Cidade</TableHead>
            <TableHead>Estado</TableHead>
            <TableHead>Ações</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          <NeighborhoodRows />
        </TableBody>
      </Table>

      <UpdateNeighborhoodDialog />
    </div>
  );
}

function NeighborhoodRows() {
  const { page, itemsPerPage, query, setTotalPages } = usePage();
  const {
    isLoading,
    isError,
    data: neighborhoods,
  } = useNeighborhoodsQuery({
    name: query,
    page,
    itemsPerPage,
  });
  const updateNeighborhoodDialog = useUpdateNeighborhoodDialog();
  const deleteNeighborhoodDialog = useDeleteNeighborhoodDialog();

  useEffect(() => {
    if (!neighborhoods) return;

    setTotalPages(neighborhoods?.totalPages);
  }, [neighborhoods]);

  if (isLoading) {
    return (
      <TableRow className="text-center w-full">
        <TableCell colSpan={4}>
          <AnimatedLoading />
        </TableCell>
      </TableRow>
    );
  }

  if (isError || !neighborhoods) {
    return (
      <TableRow className="text-center w-full">
        <TableCell colSpan={4}>Erro ao carregar os bairros</TableCell>
      </TableRow>
    );
  }

  if (neighborhoods.data.length === 0) {
    return (
      <TableRow className="text-center w-full">
        <TableCell colSpan={4}>Nenhum bairro encontrado</TableCell>
      </TableRow>
    );
  }

  return (
    <>
      {neighborhoods.data.map((neighborhood) => (
        <TableRow
          key={neighborhood.id}
          className="cursor-pointer"
          onClick={() => updateNeighborhoodDialog.open(neighborhood.id)}
        >
          <TableCell>{neighborhood.name}</TableCell>
          <TableCell>{neighborhood.city}</TableCell>
          <TableCell>{neighborhood.state}</TableCell>

          <TableCell>
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="outline">
                  <DotsHorizontalIcon className="h-3 w-3" />
                </Button>
              </DropdownMenuTrigger>

              <DropdownMenuContent>
                <DropdownMenuLabel>Ações</DropdownMenuLabel>

                <DropdownMenuItem
                  onClick={() => updateNeighborhoodDialog.open(neighborhood.id)}
                >
                  Editar bairro
                </DropdownMenuItem>

                <DropdownMenuSeparator />

                <DropdownMenuItem
                  className="text-red-500"
                  onClick={() =>
                    deleteNeighborhoodDialog.open({
                      id: neighborhood.id,
                      name: neighborhood.name,
                    })
                  }
                >
                  Excluir bairro
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </TableCell>
        </TableRow>
      ))}
    </>
  );
}
