import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { usePage } from "@/hooks/use-page";

import { usePreferencesDialog } from "../../hooks/dialogs/use-preferences-dialog";

const preferencesSchema = z.object({
  itemsPerPage: z.coerce.number().nonnegative({
    message: "O valor deve ser maior ou igual a zero",
  }),
});

export function CreatePreferencesDialog() {
  const { isOpen, setIsOpen, close } = usePreferencesDialog();
  const { itemsPerPage, setItemsPerPage } = usePage();

  function savePreferences(preferences: z.infer<typeof preferencesSchema>) {
    setItemsPerPage(preferences.itemsPerPage);
    close();
  }

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Preferências</DialogTitle>
        </DialogHeader>

        <PreferencesForm
          itemsPerPage={itemsPerPage}
          onSubmit={savePreferences}
        />
      </DialogContent>
    </Dialog>
  );
}

type PreferencesFormProps = {
  itemsPerPage: number;
  onSubmit: (preferences: z.infer<typeof preferencesSchema>) => void;
};

function PreferencesForm({ itemsPerPage, onSubmit }: PreferencesFormProps) {
  const form = useForm({
    resolver: zodResolver(preferencesSchema),

    defaultValues: {
      itemsPerPage,
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="itemsPerPage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Itens por página</FormLabel>

              <FormControl>
                <Input type="number" {...field} />
              </FormControl>

              <FormMessage />
            </FormItem>
          )}
        />
        <Button className="w-full mt-4">Salvar preferências</Button>
      </form>
    </Form>
  );
}
