import { SwitchProps } from "@radix-ui/react-switch";

import { cn } from "@/utils";

import { FormControl } from "./ui/form";
import { Switch } from "./ui/switch";

export type SwitchBoxProps = SwitchProps;

export function SwitchBox({
  children,
  className,
  ...otherProps
}: SwitchBoxProps) {
  return (
    <div
      className={cn(
        "switch-box border flex items-center rounded-xl p-4 justify-between",
        className,
      )}
    >
      <div className="switch-box-text flex flex-col gap-1">{children}</div>

      <FormControl>
        <Switch {...otherProps} />
      </FormControl>
    </div>
  );
}

export function SwitchBoxTitle({ children }: { children: React.ReactNode }) {
  return <div className="font-bold">{children}</div>;
}

export function SwitchBoxDescription({
  children,
}: {
  children: React.ReactNode;
}) {
  return <div className="text-sm text-muted-foreground">{children}</div>;
}
