import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { queryClient } from "@/app";
import api from "@/services/api";

import { USE_NEIGHBORHOODS_QUERY_KEY } from "../queries/use-neighborhoods-query";

export type CreateNeighborhoodInput = {
  name: string;
  city: string;
  state: string;
  latitude: string;
  longitude: string;
};

export async function createNeighborhood(input: CreateNeighborhoodInput) {
  const { data } = await api.post(`/neighborhood`, input);

  return data;
}

export function useCreateNeighborhoodMutation() {
  return useMutation({
    mutationFn: createNeighborhood,

    onSuccess() {
      toast.success("Sucesso", {
        description: "Bairro atualizado com sucesso",
      });

      queryClient.invalidateQueries({
        queryKey: [USE_NEIGHBORHOODS_QUERY_KEY],
      });
    },

    onError() {
      toast.error("Erro", {
        description: "Erro ao atualizar bairro",
      });
    },
  });
}
