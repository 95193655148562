import { PlusIcon } from "lucide-react";

import { Button } from "@/components/ui/button";

import { CreateNeighborhoodDialog } from "../dialogs/create-neighborhood";
import { useCreateNeighborhoodDialog } from "../hooks/dialogs/use-create-neighborhood-dialog";

export function CreateNeighborhoodButton() {
  const { open } = useCreateNeighborhoodDialog();

  return (
    <>
      <Button onPointerDown={open}>
        <PlusIcon className="w-3 h-3 mr-2" /> Criar bairro
      </Button>

      <CreateNeighborhoodDialog />
    </>
  );
}
