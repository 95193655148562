import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { queryClient } from "@/app";
import api from "@/services/api";

import { USE_NEIGHBORHOODS_QUERY_KEY } from "../queries/use-neighborhoods-query";

export type UpdateNeighborhoodInput = {
  id: string;
  name: string;
  city: string;
  state: string;
  latitude: string;
  longitude: string;
};

export async function updateNeighborhood(input: UpdateNeighborhoodInput) {
  const { data } = await api.put(`/neighborhood/${input.id}`, input);

  return data;
}

export function useUpdateNeighborhoodMutation() {
  return useMutation({
    mutationFn: updateNeighborhood,

    onSuccess() {
      toast.success("Sucesso", {
        description: "Bairro atualizado com sucesso",
      });

      queryClient.invalidateQueries({
        queryKey: [USE_NEIGHBORHOODS_QUERY_KEY],
      });
    },

    onError() {
      toast.error("Erro", {
        description: "Erro ao atualizar bairro",
      });
    },
  });
}
