import {
  MapContainer,
  TileLayer,
  Marker,
  useMapEvents,
  useMap,
} from "react-leaflet";

export type SelectPositionMapProps = {
  onSelect: (position: { latitude: number; longitude: number }) => void;
  latitude: number | null;
  longitude: number | null;
};

export const SelectPositionMap = ({
  onSelect,
  latitude,
  longitude,
}: SelectPositionMapProps) => {
  const LocationMarker = () => {
    useMapEvents({
      click(e) {
        onSelect({
          latitude: e.latlng.lat,
          longitude: e.latlng.lng,
        });
      },
    });

    return latitude && longitude ? (
      <Marker
        position={{
          lat: latitude,
          lng: longitude,
        }}
      />
    ) : null;
  };

  const CenterUpdate = () => {
    const map = useMap();

    if (latitude && longitude) {
      map.setView({
        lat: latitude,
        lng: longitude,
      });
    }

    return null;
  };

  return (
    <MapContainer
      center={[
        typeof latitude === "number" ? latitude : -5.18804,
        typeof longitude === "number" ? longitude : -37.3441,
      ]}
      zoom={13}
      className="w-full h-[300px] rounded-lg"
      attributionControl={false}
    >
      <TileLayer
        url={String(import.meta.env.VITE_TILE_LAYER_URL)}
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      />

      <LocationMarker />
      <CenterUpdate />
    </MapContainer>
  );
};
