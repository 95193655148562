import { useEffect, useState } from "react";

import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";
import { Input } from "@/components/ui/input";

import { useDeleteNeighborhoodDialog } from "../../hooks/dialogs/use-delete-neighborhood-dialog";
import { useDeleteNeighborhoodMutation } from "../../hooks/mutations/use-delete-neighborhood-mutation";

export function DeleteNeighborhoodDialog() {
  const { isOpen, setIsOpen, id, name } = useDeleteNeighborhoodDialog();
  const [nameConfirmation, setNameConfirmation] = useState("");
  const { mutate: deleteNeighborhood } = useDeleteNeighborhoodMutation();

  return (
    <AlertDialog
      open={isOpen}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          setNameConfirmation("");
        }

        setIsOpen(isOpen);
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            Tem certeza que deseja excluir este bairro?
          </AlertDialogTitle>
        </AlertDialogHeader>

        <AlertDialogDescription className="flex flex-col gap-2">
          <p>
            Esta ação é irreversível e removerá todos os dados relacionados ao
            bairro <strong>&quot;{name}&quot;</strong>
          </p>

          <p>
            Para confirmar sua intenção de excluir este bairro, digite o nome do
            bairro abaixo:
          </p>
        </AlertDialogDescription>

        <Input
          placeholder="Digite o nome do bairro"
          value={nameConfirmation}
          onChange={(e) => setNameConfirmation(e.currentTarget.value)}
        />

        <AlertDialogFooter>
          <AlertDialogCancel>Cancelar</AlertDialogCancel>

          <AlertDialogAction
            disabled={nameConfirmation != name}
            onClick={() => {
              if (!id) return;

              deleteNeighborhood(id);
              setIsOpen(false);
            }}
          >
            Excluir
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
