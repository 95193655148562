import { queryOptions, useQuery } from "@tanstack/react-query";

import { usePage } from "@/hooks/use-page";
import api from "@/services/api";

export type FetchNeighborhoodsInput = {
  name: string;
  page: number;
  itemsPerPage: number;
};

export interface FetchNeighborhoodsOutput {
  totalPages: number;
  currentPage: number;
  data: Neighborhood[];
}

export interface Neighborhood {
  id: string;
  name: string;
  city: string;
  state: string;
  regionID: string;
  regionName: string;
}

export async function fetchNeighborhoods(input: FetchNeighborhoodsInput) {
  const searchParams = new URLSearchParams({
    name: input.name,
    page: String(input.page),
    itemsPerPage: String(input.itemsPerPage),
  });

  const { data } = await api.get<FetchNeighborhoodsOutput>(
    `/neighborhood?${searchParams.toString()}`,
  );

  return data;
}

export const USE_NEIGHBORHOODS_QUERY_KEY = "USE_NEIGHBORHOODS_QUERY_KEY";

export function neighborhoodQueryOptions(input: FetchNeighborhoodsInput) {
  return queryOptions({
    queryKey: [USE_NEIGHBORHOODS_QUERY_KEY, input],
    queryFn: () => fetchNeighborhoods(input),
  });
}

export function useNeighborhoodsQuery(input: FetchNeighborhoodsInput) {
  const { itemsPerPage } = usePage();

  return useQuery(
    neighborhoodQueryOptions({
      ...input,
      itemsPerPage,
    }),
  );
}
