import { usePage } from "@/hooks/use-page";
import { SearchInput } from "@/pages/deliveries/components/search-input";

export function Search() {
  const { setQuery } = usePage();

  return (
    <SearchInput
      placeholder="Pesquisar pelo nome do bairro"
      onInput={(event) => setQuery(event.currentTarget.value)}
    />
  );
}
