import { useMutation } from "@tanstack/react-query";
import { toast } from "sonner";

import { queryClient } from "@/app";
import api from "@/services/api";

import { USE_NEIGHBORHOODS_QUERY_KEY } from "../queries/use-neighborhoods-query";

async function deleteNeighborhood(id: string) {
  return await api.delete(`/neighborhood/${id}`);
}

export function useDeleteNeighborhoodMutation() {
  return useMutation({
    mutationFn: deleteNeighborhood,

    onSuccess() {
      toast.success("Bairro excluído com sucesso");

      queryClient.invalidateQueries({
        queryKey: [USE_NEIGHBORHOODS_QUERY_KEY],
      });
    },

    onError() {
      toast.error("Erro ao excluir bairro");
    },
  });
}
