import { SearchIcon } from "lucide-react";

import { Input } from "@/components/ui/input";
import { usePage } from "@/hooks/use-page";

export type SearchInputProps = React.InputHTMLAttributes<HTMLInputElement>;

export function SearchInput(props: SearchInputProps) {
  const { setQuery } = usePage();

  return (
    <div className="search-input relative flex flex-1">
      <Input
        className="mb-4 bg-background pl-8"
        placeholder="Pesquise pelo nome do entregador"
        {...props}
        onChange={(e) => setQuery(e.currentTarget.value)}
      />

      <SearchIcon
        className="pointer-events-none absolute left-2 top-2"
        size="18"
      />
    </div>
  );
}
