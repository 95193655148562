import {
  Page,
  PageContent,
  PageHeader,
  PageOptions,
  PageTitle,
  SimplePagination,
} from "@/components/ui/page";

import { CreateNeighborhoodButton } from "./components/create-neighborhood-button";
import { NeighborhoodTable } from "./components/neighborhood-table";
import { PreferencesButton } from "./components/preferences-button";
import { Search } from "./components/search";
import { DeleteNeighborhoodDialog } from "./dialogs/delete-neighborhood";

export function NeighborhoodPage() {
  return (
    <Page>
      <PageHeader>
        <PageTitle title="Bairros" subtitle="Gerenciar bairros" />

        <PageOptions>
          <PreferencesButton />
          <CreateNeighborhoodButton />
        </PageOptions>
      </PageHeader>

      <PageContent>
        <Search />
        <SimplePagination />
        <NeighborhoodTable />
      </PageContent>

      <DeleteNeighborhoodDialog />
    </Page>
  );
}
