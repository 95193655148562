import { useQuery } from "@tanstack/react-query";

import api from "@/services/api";

export interface FetchCompaniesResponse {
  totalPages: number;
  currentPage: number;
  data: Company[];
}

export interface FetchCompaniesRequest {
  itemsPerPage?: number;
}

export interface Company {
  id: string;
  createdAt: string;
  updatedAt: string;
  companyName: string;
  city: string;
  state: string;
  planName: string;
  addressGeolocation: string;
  isBlocked: boolean;
}

export async function fetchCompanies(request: FetchCompaniesRequest) {
  const serachParams = new URLSearchParams();

  if (request.itemsPerPage) {
    serachParams.append("itemsPerPage", request.itemsPerPage.toString());
  }

  const { data } = await api.get<FetchCompaniesResponse>(
    "/company?" + serachParams.toString(),
  );

  return data;
}

export const USE_COMPANIES_QUERY_KEY = "use-companies";

export function useCompanies() {
  return useQuery({
    queryKey: [USE_COMPANIES_QUERY_KEY],
    queryFn: () =>
      fetchCompanies({
        itemsPerPage: 1000,
      }),
  });
}
