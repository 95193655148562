import { zodResolver } from "@hookform/resolvers/zod";
import { defineStepper } from "@stepperize/react";
import { ArrowLeftIcon, Loader2 } from "lucide-react";
import { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { z } from "zod";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { cn } from "@/utils";

import { SelectPositionMap } from "../../components/select-position-map";
import { useCreateNeighborhoodDialog } from "../../hooks/dialogs/use-create-neighborhood-dialog";
import { useCreateNeighborhoodMutation } from "../../hooks/mutations/use-create-neighborhood-mutation";
import { useLocationsSuggestions } from "../../hooks/queries/use-locations-query";

export function CreateNeighborhoodDialog() {
  const { isOpen, setIsOpen } = useCreateNeighborhoodDialog();

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent className="max-w-[600px]">
        <DialogHeader>
          <DialogTitle>Adicionar bairro</DialogTitle>
        </DialogHeader>

        <CreateNeighborhoodForm />
      </DialogContent>
    </Dialog>
  );
}

const basicInformationSchema = z.object({
  name: z.string().min(1, "O nome do bairro é obrigatório"),
  city: z.string().min(1, "A cidade é obrigatória"),
  state: z.string().min(1, "O estado é obrigatório"),
});

const locationSchema = z.object({
  latitude: z.number().min(-90).max(90),
  longitude: z.number().min(-180).max(180),
});

const createNeighborhoodSchema = locationSchema.merge(basicInformationSchema);

const { useStepper } = defineStepper(
  {
    id: "basicInformation",
    label: "Informações básicas",
    schema: basicInformationSchema,
  },

  {
    id: "location",
    label: "Localização",
    schema: locationSchema,
  },
);

function CreateNeighborhoodForm() {
  const stepper = useStepper();
  const form = useForm<z.infer<typeof createNeighborhoodSchema>>({
    resolver: zodResolver(stepper.current.schema),
    defaultValues: {
      name: "",
      city: "Mossoró",
      state: "RN",
    },
  });
  const { mutate: createNeighborhood } = useCreateNeighborhoodMutation();
  const { close } = useCreateNeighborhoodDialog();

  async function onSubmit() {
    if (stepper.isLast) {
      createNeighborhood({
        ...form.getValues(),
        latitude: String(form.getValues("latitude")),
        longitude: String(form.getValues("longitude")),
      });
      close();

      return;
    }

    stepper.next();
  }

  return (
    <Form {...form}>
      <form
        className="flex flex-col gap-4"
        onSubmit={form.handleSubmit(onSubmit)}
      >
        {stepper.switch({
          basicInformation: () => <BasicInformation />,
          location: () => <Location />,
        })}

        <DialogFooter>
          <div className="flex flex-col w-full gap-2">
            {!stepper.isFirst && (
              <Button variant="outline" type="button" onClick={stepper.prev}>
                <ArrowLeftIcon className="w-3 h-3 mr-2" />
                Voltar
              </Button>
            )}

            <Button className="w-full">
              {stepper.isLast ? "Criar bairro" : "Próximo"}
            </Button>
          </div>
        </DialogFooter>
      </form>
    </Form>
  );
}

function BasicInformation() {
  const form = useFormContext<z.infer<typeof basicInformationSchema>>();

  return (
    <>
      <FormField
        control={form.control}
        name="name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Nome</FormLabel>

            <FormControl>
              <Input {...field} autoFocus />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="city"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Cidade</FormLabel>

            <FormControl>
              <Input {...field} disabled />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="state"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Estado</FormLabel>

            <FormControl>
              <Input {...field} disabled />
            </FormControl>

            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}

function Location() {
  const form = useFormContext<z.infer<typeof locationSchema>>();

  return (
    <>
      <AutoCompleteLocation
        onSelect={({ latitude, longitude }) => {
          form.setValue("latitude", latitude);
          form.setValue("longitude", longitude);
        }}
      />

      <Label>Selecione a posição no mapa</Label>

      <SelectPositionMap
        latitude={form.watch("latitude")}
        longitude={form.watch("longitude")}
        onSelect={({ latitude, longitude }) => {
          form.setValue("latitude", latitude);
          form.setValue("longitude", longitude);
        }}
      />
    </>
  );
}

type AutoCompleteLocationProps = {
  onSelect: (latLong: { latitude: number; longitude: number }) => void;
};

function AutoCompleteLocation({ onSelect }: AutoCompleteLocationProps) {
  const [query, setQuery] = useState("");
  const { isLoading, data: suggestions } = useLocationsSuggestions(query);

  return (
    <div className="flex flex-col gap-2 w-full">
      <Label>Endereço</Label>

      <div className="group relative w-full">
        <Input
          value={query}
          onChange={(e) => setQuery(e.currentTarget.value)}
        />

        <div
          className={cn(
            "absolute top-12 bg-card border rounded-lg z-[10000] w-full group-focus-within:opacity-100 opacity-0 transition-all duration-200",
          )}
        >
          {isLoading && (
            <div className="p-2 text-sm flex justify-center gap-2 items-center">
              <Loader2 className="animate-spin w-3 h-3" />
              Carregando sugestões...
            </div>
          )}

          {(suggestions || []).length === 0 && !isLoading && (
            <div className="p-2 text-sm">Nenhuma sugestão encontrada</div>
          )}

          {(suggestions || []).map((suggestion) => (
            <div
              role="button"
              className="hover:bg-muted p-2 text-sm"
              onClick={() => {
                setQuery(suggestion.display_name);
                onSelect({
                  latitude: Number(suggestion.lat),
                  longitude: Number(suggestion.lon),
                });
              }}
              key={suggestion.place_id}
            >
              {suggestion.display_name}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
