import { create } from "zustand";

export type UseCreateNeighborhoodDialogState = {
  isOpen: boolean;

  open: () => void;
  close: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export const useCreateNeighborhoodDialog =
  create<UseCreateNeighborhoodDialogState>((set) => ({
    isOpen: false,
    id: null,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
    setIsOpen: (isOpen) => set({ isOpen }),
  }));
