import createFetchClient from "openapi-fetch";
import createClient from "openapi-react-query";

import { TOKEN_KEY } from "@/context/auth-context";

import type { paths } from "./users-api-schema";

const fetchClient = createFetchClient<paths>({
  baseUrl: import.meta.env.VITE_USERS_API_URL,
});

fetchClient.use({
  onRequest: ({ request }) => {
    const token = localStorage.getItem(TOKEN_KEY);

    if (token) {
      request.headers.set("Authorization", `Bearer ${token}`);
    }

    return request;
  },
});

export const usersApi = createClient(fetchClient);
