import { useDebouncedState } from "@mantine/hooks";
import { ChevronLeftIcon, ChevronRightIcon } from "lucide-react";
import { createContext, useContext, useEffect, useState } from "react";

import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { usePage } from "@/hooks/use-page";
import { cn } from "@/utils";

export type PageContextType = {
  title: string;
  setTitle: (title: string) => void;

  page: number;
  setPage: (page: number) => void;
  nextPage: () => void;
  previousPage: () => void;

  totalPages: number;
  setTotalPages: (totalPages: number) => void;

  itemsPerPage: number;
  setItemsPerPage: (itemsPerPage: number) => void;

  canGoBack: boolean;
  canGoNext: boolean;

  query: string;
  setQuery: (query: string) => void;
};

export const PageContext = createContext({} as PageContextType);

export const Page = ({ children }: { children: React.ReactNode }) => {
  const defaultTitle = import.meta.env.VITE_APP_NAME as string;
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [query, setQuery] = useDebouncedState("", 500);

  function nextPage() {
    if (page >= totalPages) return;

    setPage(page + 1);
  }

  function previousPage() {
    if (page <= 1) return;

    setPage(page - 1);
  }

  useEffect(() => {
    setPage(1);
  }, [query]);

  return (
    <PageContext.Provider
      value={{
        title: defaultTitle,

        setTitle: (title: string) => {
          document.title = `${title} | ${defaultTitle}`;
        },

        page,
        setPage,

        nextPage,
        previousPage,

        totalPages,
        setTotalPages,

        itemsPerPage,
        setItemsPerPage,

        canGoBack: page > 1,
        canGoNext: page < totalPages,

        query,
        setQuery,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export type PageContentProps = React.ComponentPropsWithoutRef<"div">;

export function PageContent({ className, ...otherProps }: PageContentProps) {
  return (
    <div className={cn("flex flex-col gap-4", className)} {...otherProps} />
  );
}

export type PageHeaderProps = React.ComponentPropsWithoutRef<"div">;

export function PageHeader({ className, ...otherProps }: PageHeaderProps) {
  return (
    <div
      className={cn("flex items-center justify-between", className)}
      {...otherProps}
    />
  );
}

export type PageTitleProps = {
  title: string;
  subtitle: string;
};

export function PageTitle({ title, subtitle }: PageTitleProps) {
  const page = useContext(PageContext);

  useEffect(() => {
    const previousTitle = document.title;

    page.setTitle(title);

    return () => {
      document.title = previousTitle;
    };
  }, [title]);

  useEffect(() => {
    return () => {
      page.setItemsPerPage(10);
      page.setPage(1);
    };
  }, []);

  if (!page) {
    throw new Error("PageHeaderTitle must be used inside a PageContext");
  }

  return (
    <hgroup className="py-4">
      <h1 className="text-lg font-semibold">{title}</h1>
      <h2 className="text-sm text-muted-foreground">{subtitle}</h2>
    </hgroup>
  );
}

export type PageOptionsProps = React.ComponentPropsWithoutRef<"div">;

export function PageOptions({ className, ...otherProps }: PageOptionsProps) {
  return <div className={cn("flex gap-2", className)} {...otherProps} />;
}

export function SimplePagination() {
  const { page, totalPages, canGoBack, canGoNext, previousPage, nextPage } =
    usePage();

  return (
    <div className="pagination mb-2 flex items-center justify-between gap-4">
      <div className="pagination-details flex w-full items-center justify-end gap-2">
        <div className="flex items-center gap-1 text-xs">
          Página <strong>{page}</strong> de
          <strong>
            {totalPages || <Skeleton className="inline-flex h-4 w-4" />}
          </strong>
        </div>

        <TooltipProvider delayDuration={0}>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                disabled={!canGoBack}
                onClick={previousPage}
              >
                <ChevronLeftIcon className="h-3 w-3" />
              </Button>
            </TooltipTrigger>

            <TooltipContent>Página anterior</TooltipContent>
          </Tooltip>

          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="icon"
                disabled={!canGoNext}
                onClick={nextPage}
              >
                <ChevronRightIcon className="h-3 w-3" />
              </Button>
            </TooltipTrigger>

            <TooltipContent>Próxima página</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </div>
    </div>
  );
}

export function PageSkeleton() {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex items-center justify-between">
        <hgroup className="py-4">
          <h1 className="text-lg font-semibold">
            <Skeleton className="h-[1.5rem] w-[100px]" />
          </h1>

          <h2 className="text-sm text-muted-foreground mt-1">
            <Skeleton className="h-[0.8rem] w-[150px]" />
          </h2>
        </hgroup>

        <div className="flex gap-2">
          <div className="h-9 w-[30px] lg:w-[40px] animate-pulse rounded-md bg-zinc-700" />
          <div className="h-9 w-[90px] lg:w-[150px] animate-pulse rounded-md bg-zinc-300" />
        </div>
      </div>

      <div className="h-8 w-full animate-pulse rounded-md bg-zinc-300" />

      <div className="flex items-center flex-row-reverse w-full gap-2">
        <div className="h-9 w-[40px] animate-pulse rounded-md bg-zinc-400" />
        <div className="h-9 w-[40px] animate-pulse rounded-md bg-zinc-400" />
        <div className="h-4 w-[100px] animate-pulse rounded-md bg-zinc-400" />
      </div>

      <div className="h-[70vh] bg-zinc-300 animate-pulse rounded-lg" />
    </div>
  );
}
