import { create } from "zustand";

export type UsePreferencesDialogState = {
  isOpen: boolean;

  open: () => void;
  close: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export const usePreferencesDialog = create<UsePreferencesDialogState>(
  (set) => ({
    isOpen: false,
    open: () => set({ isOpen: true }),
    close: () => set({ isOpen: false }),
    setIsOpen: (isOpen) => set({ isOpen }),
  }),
);
