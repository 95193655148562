import * as Sentry from "@sentry/react";
import { lazy, Suspense, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { MainContent } from "@/components";
import { AnimatedLoading } from "@/components/animated-loading";
import { PageSkeleton } from "@/components/ui/page";
import { AuthContext } from "@/context/auth-context";
import { usersCredencial } from "@/enums/user.enum";
import { NeighborhoodPage } from "@/pages/neighborhood";
import UserProfileView from "@/pages/user-profile";
import { UpdateUserPage } from "@/pages/users/update";

const Deliverymans = lazy(() =>
  import("@/pages/deliverymans").then((module) => ({
    default: module.Deliverymans,
  })),
);
const StatsPage = lazy(() =>
  import("@/pages/stats").then((module) => ({ default: module.StatsPage })),
);
const Companies = lazy(() =>
  import("@/pages/companies").then((module) => ({ default: module.Companies })),
);
const UsersPage = lazy(() =>
  import("@/pages/users").then((module) => ({ default: module.UsersPage })),
);
const PlansView = lazy(() => import("@/pages/plans"));
const ClientsView = lazy(() => import("@/pages/clients"));
const RegisterClientView = lazy(() => import("@/pages/clients/registerView"));
const ReportsView = lazy(() => import("@/pages/reports"));
const LoginForm = lazy(() =>
  import("@/pages/login").then((module) => ({ default: module.LoginForm })),
);
const Delivery = lazy(() =>
  import("@/pages/deliveries").then((module) => ({ default: module.Delivery })),
);
const RegionsPage = lazy(() =>
  import("@/pages/regions").then((module) => ({ default: module.RegionsPage })),
);
const PricesPage = lazy(() =>
  import("@/pages/regions/prices").then((module) => ({
    default: module.PricesPage,
  })),
);

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const Router = () => {
  const { user } = useContext(AuthContext);
  const isDeliveryMan = user?.UserRoles.map((item) => {
    if (Number(item?.roleID) === usersCredencial.DELIVERYMAN) {
      return true;
    }
    return false;
  });

  const functionPermission = (permission?: boolean[]) => {
    return permission?.includes(true);
  };

  return (
    <SentryRoutes>
      <Route
        path="/"
        element={
          <Suspense fallback={<AnimatedLoading />}>
            <LoginForm />
          </Suspense>
        }
      />

      <Route path="/dashboard">
        <Route
          index
          element={
            <MainContent>
              <Navigate
                to={
                  functionPermission(isDeliveryMan)
                    ? "/dashboard/reports"
                    : "/dashboard/deliveries"
                }
              />
            </MainContent>
          }
        />

        <Route path="users">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <UsersPage />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path="update/:id"
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <UpdateUserPage />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="clients">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <ClientsView />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path=":type/:id?"
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <RegisterClientView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="plans">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <PlansView />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="neighborhoods">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <NeighborhoodPage />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route path="regions">
          <Route
            index
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <RegionsPage />
                </Suspense>
              </MainContent>
            }
          />

          <Route
            path="prices/:id"
            element={
              <MainContent>
                <Suspense fallback={<PageSkeleton />}>
                  <PricesPage />
                </Suspense>
              </MainContent>
            }
          />
        </Route>

        <Route
          path="companies"
          element={
            <MainContent>
              <Suspense fallback={<PageSkeleton />}>
                <Companies />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          path="deliverymans"
          element={
            <MainContent>
              <Suspense fallback={<PageSkeleton />}>
                <Deliverymans />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          path="reports"
          element={
            <MainContent>
              <Suspense fallback={<PageSkeleton />}>
                <ReportsView />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          path="stats"
          element={
            <MainContent>
              <Suspense fallback={<PageSkeleton />}>
                <StatsPage />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          path="deliveries"
          element={
            <MainContent>
              <Suspense fallback={<PageSkeleton />}>
                <Delivery />
              </Suspense>
            </MainContent>
          }
        />

        <Route
          index
          path="userProfile/:id?"
          element={
            <MainContent>
              <Suspense fallback={<PageSkeleton />}>
                <UserProfileView />
              </Suspense>
            </MainContent>
          }
        />

        <Route path="*" element={<Navigate to="/dashboard/deliveries" />} />
      </Route>

      <Route path="*" element={<h1>Esta pagina não foi encontrada</h1>} />
    </SentryRoutes>
  );
};

export default Router;
