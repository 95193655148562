import { useParams } from "react-router-dom";

import { Page, PageContent, PageHeader, PageTitle } from "@/components/ui/page";

import { UpdateUser } from "./components/update-user-form";

export function UpdateUserPage() {
  const { id } = useParams<{ id: string }>();

  if (!id) {
    throw new Error("Id is required");
  }

  return (
    <Page>
      <PageHeader>
        <PageTitle
          title="Atualizar usuário"
          subtitle="Atualize os dados do usuário"
        />
      </PageHeader>

      <PageContent>
        <UpdateUser userId={id} />
      </PageContent>
    </Page>
  );
}
