import { create } from "zustand";

export type UseDeleteNeighborhoodDialogState = {
  isOpen: boolean;
  id: string | null;
  name: string | null;

  open: (details: { name: string; id: string }) => void;
  close: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export const useDeleteNeighborhoodDialog =
  create<UseDeleteNeighborhoodDialogState>((set) => ({
    isOpen: false,
    id: null,
    name: null,

    open: ({ id, name }) => set({ isOpen: true, id, name }),
    close: () => set({ isOpen: false }),
    setIsOpen: (isOpen) => set({ isOpen }),
  }));
