import { useQuery } from "@tanstack/react-query";
import ky from "ky";

export interface Suggestion {
  place_id: number;
  licence: string;
  osm_type: string;
  osm_id: number;
  lat: string;
  lon: string;
  class: string;
  type: string;
  place_rank: number;
  importance: number;
  addresstype: string;
  name: string;
  display_name: string;
  address: Address;
  boundingbox: string[];
}

export interface Address {
  shop?: string;
  road: string;
  neighbourhood?: string;
  suburb: string;
  city: string;
  municipality: string;
  county?: string;
  state_district: string;
  state: string;
  "ISO3166-2-lvl4": string;
  region: string;
  postcode: string;
  country: string;
  country_code: string;
  highway?: string;
  city_district?: string;
  house_number?: string;
  building?: string;
  amenity?: string;
}

function fetchLocationsSuggestions(query: string) {
  const searchParams = new URLSearchParams({
    q: `${query}, Mossoró`,
    format: "json",
    addressdetails: "1",
    limit: "5",
  });

  return ky
    .get("https://nominatim.openstreetmap.org/search", {
      searchParams,
    })
    .json<Suggestion[]>();
}

export const USE_LOCATIONS_SUGGESTIONS = "useLocationsSuggestions";

export function useLocationsSuggestions(query: string) {
  return useQuery({
    queryKey: [USE_LOCATIONS_SUGGESTIONS, query],
    queryFn: () => fetchLocationsSuggestions(query),
    enabled: query.length > 2,
  });
}
