import { create } from "zustand";

export type UseUpdateNeighborhoodDialogState = {
  isOpen: boolean;
  id: string | null;

  open: (id: string) => void;
  close: () => void;
  setIsOpen: (isOpen: boolean) => void;
};

export const useUpdateNeighborhoodDialog =
  create<UseUpdateNeighborhoodDialogState>((set) => ({
    isOpen: false,
    id: null,
    open: (id) => set({ isOpen: true, id }),
    close: () => set({ isOpen: false }),
    setIsOpen: (isOpen) => set({ isOpen }),
  }));
