import { useQuery } from "@tanstack/react-query";

import api from "@/services/api";

export interface FetchNeighborhoodResponse {
  id: string;
  name: string;
  city: string;
  state: string;
  regionID: string;
  regionName: string;
  latitude: string;
  longitude: string;
}

async function fetchNeighborhoodById(id: string) {
  const { data } = await api.get<FetchNeighborhoodResponse>(
    `/neighborhood/${id}`,
  );

  return data;
}

export const USE_NEIGHBORHOOD_QUERY_KEY = "useNeighborhoodQuery";

export function useNeighborhoodQuery(id: string | null | undefined) {
  return useQuery({
    queryKey: [USE_NEIGHBORHOOD_QUERY_KEY, id],
    queryFn: () => {
      if (!id) {
        throw new Error("ID is required");
      }

      return fetchNeighborhoodById(id);
    },
    enabled: !!id,
  });
}
